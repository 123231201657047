import $ from 'jquery'

$(document).ready( initHomeHero )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=home-hero', initHomeHero)
}

function initHomeHero(){
  if ( $('.home-hero').length ) {
    $('.header').addClass('header--transparent');
  }
  if ($('.home-hero__images').length) {

    let num = $('.home-hero__images').data('images');
    let rand = Math.floor(Math.random() * num);
    let shown_image = '.home-hero__image--' + rand;

    $(shown_image).show();
  }
}
